<template>
  <b-card>
    <g-form>
      <b-row>
        <!-- fromDate  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('hideZeroBalances')">
            <b-form-checkbox
              v-model="selectedItem.hideZeroBalances"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
        >
          <b-form-group :label="$t('ShowAllBranches')">
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- operations buttons -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mx-1"
            variant="relief-primary"
            @click="print('linear')"
          >
            <feather-icon
              class="mr-25"
              icon="ArrowUpIcon"
            />
            {{ $t('reviewLinear') }}
          </b-button>
          <b-button
            class="mx-1"
            variant="relief-primary"
            @click="print('transverse')"
          >
            <feather-icon
              class="mr-25"
              icon="ArrowRightIcon"
            />
            {{ $t('reviewTransverse') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'

export default {
    mixins: [
        reportMixin,
    ],
    data() {
        return {
            selectedItem: {
                fromDate: '',
                toDate: this.today
            },
        };
    },
    computed: {},
    beforeMount() {
        this.fiscalYearStart = this.currentYear.startDate;
        this.fiscalYearEnd = this.currentYear.endDate;
    },
    mounted() {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    },
    methods: {
      checkDate() {
        if (this.selectedItem.fromDate === null) {
            this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
        }
        if (this.selectedItem.toDate === null) {
            this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
        }
      },

      prepareForPrint() {
        const allbranches = reportParameters.find((par) => par.id === 'allBranches');
        this.selectedItem.branchName = this.selectedItem.ShowAllBranches
                                     ? this.isRight ? allbranches.arabicName : allbranches.englishName
                                     : this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
      },

      print(type) {
        this.checkDate();
        this.prepareForPrint();
        if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
        if (type === 'linear') {
            this.printReport(this.isRight ? 'BalanceSheet-ar' : 'BalanceSheet-en', this.selectedItem);
        }
        if (type === 'transverse') {
            this.printReport(this.isRight ? 'BalanceSheet-transverse-ar' : 'BalanceSheet-transverse-en', this.selectedItem);
        }
      },
    },
};
</script>
