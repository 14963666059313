<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- fromDate  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>
        <!-- branches -->
        <b-col
          v-if="type.route !== 'trial-balance-report'"
          md="4"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.groupId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="accountGroups"
              field="select"
              name="accountGroups"
              :options="groups"
              label="arabicName"
              @change="getAccounts"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="type.route === 'trial-balance-report'"
          md="4"
        >
          <g-field
            :value.sync="selectedItem.level"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            :options="levels"
            label-text="maxLevel"
            field="select"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="type.route !== 'trial-balance-report'"
          md="4"
        >
          <g-field
            :value.sync="selectedItem.id"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            :options="subAccounts"
            label-text="accountName"
            field="select"
          />
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('hideZeroBalances')">
            <b-form-checkbox
              v-model="selectedItem.hideZeroBalances"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="profile.permissions.indexOf('viewShowAllBranchesInReports') > -1 || profile.isAdmin"
          md="2"
        >
          <b-form-group :label="$t('ShowAllBranches')">
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- incase account statment report -->
      <b-row
        v-if="type.route !== 'trial-balance-report'"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mr-1"
            variant="relief-primary"
            @click="print('total')"
          >
            <feather-icon
              class="mr-25"
              icon="ListIcon"
            />
            {{ $t('printTotal') }}
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
          >
            <feather-icon
              class="mr-25"
              icon="LoaderIcon"
            />
            {{ $t('printDetails') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- incase trial balance report -->
      <b-row
        v-if="type.route === 'trial-balance-report'"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            {{ $t('review') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

  <script>
  import reportMixin from '@/mixin/reportMixin';
  import { reportParameters } from '@/libs/acl/Lookups';

  export default {
     mixins: [
      reportMixin,
    ],
   props: {
      type: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        customers: [],
        agents: [],
        suppliers: [],
        filterOn: [],
        subAccounts: [],
        searchQuery: '',
        lines: [],
        groups: [],
        levels: [],
        isTableBusy: false,
        selectedItem: {
          id: null,
          groupId: null,
          level: null,
          fromDate: this.today,
          toDate: this.today
        },
      };
    },
    beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
    mounted() {
      this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
      this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
      this.getItems();
      this.getAccounts();
      this.getMaxLevel();
    },

    methods: {
      checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
      getItems() {
        this.get({ url: 'AccountGroups' }).then((data) => {
          this.groups = data;
        })
      },

      getAccounts() {
         let filter = '';
         if (this.selectedItem.groupId) {
           filter = `&groupId=${this.selectedItem.groupId}`;
         }
         if (this.selectedItem.branchId) {
           filter += `&branchId=${this.selectedItem.branchId}`;
         }
         if (filter) {
            this.get({ url: `accounts?type=sub${filter}` }).then((data) => {
              data.forEach(element => {
                element.arabicName = `${element.code} - ${element.arabicName}`;
                element.englishName = `${element.code} - ${element.englishName}`;
              });
               this.subAccounts = data;
             });
         } else {
             this.get({ url: 'accounts?type=sub' }).then((data) => {
               data.forEach(element => {
                element.arabicName = `${element.code} - ${element.arabicName}`;
                element.englishName = `${element.code} - ${element.englishName}`;
              });
             this.subAccounts = data;
        });
        }
      },

      getMaxLevel() {
        this.get({ url: `accounts/max-level?branchId=${this.branchId}` }).then((data) => {
          if (data > 0) {
            for (var i = 1; i <= data; i++) {
               this.levels.push({ id: i, arabicName: i, englishName: i })
          }
          return;
        }
          this.levels = [];
        });
      },

      prepareItemForPrint() {
        const allbranches = reportParameters.find((par) => par.id === 'allBranches');
        this.selectedItem.branchName = this.selectedItem.ShowAllBranches
                                     ? this.isRight ? allbranches.arabicName : allbranches.englishName
                                     : this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
      },

      getReportName(type) {
        let repName = '';
        if (type === 'total') {
          repName = this.isRight ? 'AccountStatementTotal-ar' : 'AccountStatementTotal-en';
          this.level = null;
        } else if (this.type.route === 'trial-balance-report') {
          repName = this.isRight ? 'TrialBalance-ar' : 'TrialBalance-en';
          this.selectedItem.id = null;
          this.selectedItem.groupId = null;
        } else {
          repName = this.isRight ? 'AccountStatementDetails-ar' : 'AccountStatementDetails-en';
          this.level = null;
        }

        return repName
      },

      print(type) {
        this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
        this.prepareItemForPrint();
        const repName = this.getReportName(type);
        this.printReport(repName, this.selectedItem);
      },
    },
  };
  </script>
