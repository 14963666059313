<template>
  <b-card>
    <g-form @click="print">

      <b-row>
        <!-- from date  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <!-- bank  -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.bankId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="bank"
              field="select"
              name="bankId"
              :options="banks"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="profile.permissions.indexOf('viewShowAllBranchesInReports') > -1 || profile.isAdmin"
          md="2"
        >
          <b-form-group :label="$t('ShowAllBranches')">
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- total and details buttons -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            @click="print('total')"
          >
            <feather-icon
              class="mr-25"
              icon="ListIcon"
            />
            {{ $t("printTotal") }}
          </b-button>
          <b-button
            class="ml-1"
            variant="outline-primary"
            @click="print('details')"
          >
            <feather-icon
              class="mr-25"
              icon="LoaderIcon"
            />
            {{ $t("printDetails") }}
          </b-button>
        </b-col>
      </b-row>

    </g-form>
  </b-card>
</template>

<script>
import { reportParameters } from '@/libs/acl/Lookups'
import reportMixin from '@/mixin/reportMixin';

export default {
  mixins: [reportMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      banks: [],
      selectedItem: {
        bankId: null,
        bankName: null,
        fromDate: this.today,
        toDate: this.today
      },
    };
  },
  computed: {},
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.getData();
  },
  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {
      this.get({ url: 'banks' }).then((data) => {
          this.banks = data;
      })
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    getReportName(repType) {
      let repName = '';
      if (repType === 'total') {
        repName = this.isRight ? 'BanksAccountStatementSummary-ar' : 'BanksAccountStatementSummary-en';
      } else if (repType === 'details') {
        repName = this.isRight ? 'BanksAccountStatement-ar' : 'BanksAccountStatement-en';
      }
      return repName;
    },

    preparePrametersForPrint() {
      const selectedBank = this.filterDataBaseOnId(this.banks, this.selectedItem.bankId);
      const allBanks = this.filterDataBaseOnId(reportParameters, 'allBanks');
      this.selectedItem.bankName = this.selectedItem.bankId
                                 ? this.isRight ? selectedBank.arabicName : selectedBank.englishName
                                 : this.isRight ? allBanks.arabicName : allBanks.englishName;

      const allbranches = this.filterDataBaseOnId(reportParameters, 'allBranches');
      this.selectedItem.branchName = this.selectedItem.ShowAllBranches
                                    ? this.isRight ? allbranches.arabicName : allbranches.englishName
                                    : this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
    },

    print(repType) {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.preparePrametersForPrint();
      const repName = this.getReportName(repType);
      this.printReport(repName, this.selectedItem);
    },
  },
};
</script>
