<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- fromDate  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('hideZeroBalances')">
            <b-form-checkbox
              v-model="selectedItem.hideZeroBalances"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="profile.permissions.indexOf('viewShowAllBranchesInReports') > -1 || profile.isAdmin"
          md="2"
        >
          <b-form-group :label="$t('ShowAllBranches')">
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

  <script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups';

export default {
  mixins: [
    reportMixin,
  ],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedItem: {
        fromDate: null,
        toDate: null
      },
    };
  },
  computed: {},
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
  },
  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    prepareItemForPrint() {
      const allbranches = reportParameters.find((par) => par.id === 'allBranches');
      this.selectedItem.branchName = this.selectedItem.ShowAllBranches
                                    ? this.isRight ? allbranches.arabicName : allbranches.englishName
                                    : this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
    },

    print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.prepareItemForPrint();
      const reportName = this.isRight ? 'IncomeStatement-ar' : 'IncomeStatement-en';
      this.printReport(reportName, this.selectedItem);
    },
  },
};
  </script>
