<template>
  <b-card>
    <g-form>
      <b-row>
        <!-- from Date  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <!-- supplier  -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.supplierId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="supplier"
              field="select"
              name="supplierId"
              :options="suppliers"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- show all branches -->
        <b-col
          v-if="profile.permissions.indexOf('viewShowAllBranchesInReports') > -1 || profile.isAdmin"
          md="2"
        >
          <b-form-group :label="$t('ShowAllBranches')">
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- preview button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            @click="print()"
          >
            <feather-icon
              class="mr-25"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { reportParameters } from '@/libs/acl/Lookups'
import reportMixin from '@/mixin/reportMixin';

export default {
  mixins: [reportMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      suppliers: [],
      selectedItem: {
        supplierId: null,
        safeName: null,
        fromDate: this.today,
        toDate: this.today
      },
    };
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.getData();
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {
      this.get({ url: 'Suppliers' }).then((data) => {
          this.suppliers = data;
      })
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      const selectedSupplier = this.filterDataBaseOnId(this.suppliers, this.selectedItem.supplierId);
      const allSuppliers = this.filterDataBaseOnId(reportParameters, 'allSuppliers')
      this.selectedItem.supplierName = this.selectedItem.supplierId
                                     ? this.isRight ? selectedSupplier.arabicName : selectedSupplier.englishName
                                     : this.isRight ? allSuppliers.arabicName : allSuppliers.englishName;

      const allbranches = this.filterDataBaseOnId(reportParameters, 'allBranches');
      this.selectedItem.branchName = this.selectedItem.ShowAllBranches
                                   ? this.isRight ? allbranches.arabicName : allbranches.englishName
                                   : this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
    },

    print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'SupplierExpenses-ar' : 'SupplierExpenses-en', this.selectedItem);
    },
  },
};
</script>
